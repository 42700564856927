<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="$t('label.nuevo')+$t('label.yard')"
      color="dark"
      :show.sync="ActualizaPatio"
    >
      <CRow>
        <CCol sm="12">
        
        </CCol>
      </CRow>
      <template #footer>
        <CButton color="add">
          <CIcon name="checkAlt"/>&nbsp;
          {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="ActualizaPatio=false">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
//DATA
function data () {
  return {
    //MODELO
    //VARIABLES
    Loading: false,
    ActualizaPatio: false,
  }
}

export default {
  name: 'patio-modal',
  data,
  props: {
    modal: null
  },
  watch: {
    modal: function () {
      if (this.modal) {
        this.ActualizaPatio = true;
        this.$emit('cerrarModal');
      }
    },
  },
  methods: {
  },
  computed: {
  }
}
</script>
<style scoped>
.alto {
  height: 200px;
}

.no-valido {
  border: 1px solid #e55353;
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center left;
}

.valido {
  border: 1px solid #2eb85c;
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232eb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center left;
}
</style>